<template>
  <div class="book_catlog" flex>
    <div class="left">
      <catlog></catlog>
    </div>
    <div class="right">
      <bookScore></bookScore>
      <throwTicket></throwTicket>
      <rewardGift></rewardGift>
    </div>
  </div>
</template>
<script>
import catlog from "@/components/show_book/catlog.vue";
import bookScore from "@/components/show_book/right/book_score";
import throwTicket from "@/components/show_book/right/throw_ticket";
import rewardGift from "@/components/show_book/right/reward_gift";
export default {
  components: {
    catlog,
    bookScore,
    throwTicket,
    rewardGift,
  },
};
</script>
<style lang="scss" scoped>
.book_catlog {
  width: 100%;
  .left {
    width: 74%;
  }
  .right {
    width: 25%;
    margin-left: 12px;
  }
}
</style>
