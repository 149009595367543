<template>
    <div class="book_catlogbox radius">
        <el-collapse v-if="catlog&&catlog.length" v-model="activeName">
            <el-collapse-item v-for="(item,index) in catlog"
                              class="collapse_item"
                              :name="index"
                              :key="item.id">
                <template slot="title">
                    第{{ item.chapter_num }}卷 {{ item.chapter_name }}
                </template>
                <div class="book_log" flex align>
                    <div v-for="el in item.directory"
                         :key="el.id" class="book_log_item"
                    >
                        <div flex align @click="read_book(el.page)">
                            <p class="item_left">{{ el.title }}</p>
                            <span></span>
                            <button type="button"
                                    key-button
                                    v-if="el.is_pay===1"
                                    class="book_log_button">{{ el.is_order ? '已付费' : '付 费' }}
                            </button>
                        </div>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>
        <div v-else class="no_content">
            作者正在努力创作，敬请期待！
        </div>
    </div>
</template>
<script>
import {books_directory} from "@/https/read_books.js"

export default {
    data() {
        return {
            activeName: [0],
            catlog: [
                {
                    id: 1,
                    name: '卷名称',
                    chapter: [
                        {
                            id: 1,
                            name: '章节名称',
                            free: false
                        }
                    ]
                }
            ],
            remain: null,
            len: null,
        }
    },
    created() {
    },
    mounted() {
        this.avoid_book_detail()
        // document.documentElement.scrollTop=0
    },
    methods: {
        // 阅读
        read_book(page) {
            this.$router.push({
                path: '/read_book',
                query: {
                    id: Number(sessionStorage.getItem("book_id")),
                    page: page
                }
            })
        },
        // 获取目录
        avoid_book_detail() {
            let params = {
                books_id: Number(sessionStorage.getItem("book_id"))
                // books_id:1
            }
            books_directory(params).then((res) => {
                if (res.code === 0) {
                    this.catlog = res.data
                } else {
                    this.$message.warning(res.msg)
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../utils/tool.scss";

.book_catlogbox {
    min-height: 900px;
    font-size: 14px;

    .book_log {
        padding: 0 50px;
        flex-wrap: wrap;
        justify-content: flex-start;
        //   table {
        //       width: calc(100%-100px);
        //       tr {
        //           height: 50px;
        //           td {
        //               width: calc(100%/5);
        //               border-bottom: 1px solid rgba(228, 228, 228, 1);
        //           }
        //       }
        //   }
        .book_log_item {
            width: calc(100% / 3);
            padding: 0 10px;

            //   line-height: 50px;

            > div {
                height: 50px;
                border-bottom: 1px solid rgba(228, 228, 228, 1);
                justify-content: space-between;
                padding: 0 8px;

                p {
                    display: inline-block;
                    line-height: 40px;
                    font-size: 14px;
                    max-width: 290px;
                    cursor: pointer;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .book_log_button {
                    width: 40px;
                    min-width: 40px;
                    height: 30px;
                    border-radius: 5px;
                    white-space: nowrap;
                    background-color: #EE7600;
                    color: #ffffff;
                    font-weight: bold;
                    margin-left: 10px;
                    font-size: 12px;
                }
            }

            &:hover p {
                color: #409eff
            }
        }
    }

    > .el-collapse {
        border-top: none;
    }
}

.no_content {
    height: 100px;
    @include flex-center();
}

/deep/ .el-collapse-item__header {
    padding-left: 10px;
    border-radius: 4px
}

/deep/ .el-collapse-item__header:hover {
    background: #d1e8ff
}

/deep/ .el-collapse-item__header {
    font-size: 16px;
    font-weight: bold;
}
</style>
